@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
.s-table {
  background-color: #3c4b64f5;
  color: white;
}

.border-circle__group-select {
  border-radius: 0px 5px 5px 0px !important;
}

h5 {
  color: #cccccd;
}

body {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
}
